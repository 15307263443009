<template>
  <div class="box">
    <div class="mb-3">
      <div class="fz-20 mb-1">
        User Transaction History
      </div>
      <v-layout row justify-space-between>
        <v-flex xs6>
          <div class="date-wrap">
            <DatePickerRange @changed-date="changedDate" />
          </div>
        </v-flex>
        <v-flex xs6>
          <v-select
            v-model="sortBy"
            :items="items"
            item-text="name"
            item-value="value"
            label="Select"
            return-object
            solo
            hide-details
            @change="refreshPagination()"
          />
        </v-flex>
      </v-layout>
    </div>
    <v-data-table
      :items="dataList"
      :pagination.sync="pagination"
      :loading="isLoading"
      :total-items="total"
      :rows-per-page-items="[10, 25, 50, 100]"
      class="elevation-1"
      no-data-text="No transactions data yet"
      :headers-length="4"
    >
      <template slot="items" slot-scope="props">
        <td>
          {{ props.item.type }}
        </td>
        <td class="text-xs-left">
          {{ dateFormat(props.item.date) }}
        </td>
        <td class="text-xs-left">
          <span
            :class="`${+props.item.amount > 0 ? 'green-text' : 'red-text'}`"
            class="bold"
          >
            ${{ props.item.amount }}
          </span>
        </td>
      </template>
    </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        @input="refreshPagination"
      />
    </div>
  </div>
</template>

<script>
import DatePickerRange from "@/components/Elements/DatePickerRange"
import moment from "moment"
import SavesPagination from "../../mixins/SavesPaginationMixin"
import { userTransactionListRequest } from "@/api/transaction"
import { fetchData } from "../../utils/response"

export default {
  name: "UserTransaction",
  components: {
    DatePickerRange
  },
  mixins: [SavesPagination],
  data() {
    return {
      pagination: {},
      total: 0,
      dataList: [],
      isLoading: false,
      date_from: null,
      date_to: null,
      sortBy: { name: "Newest are first", value: "newest" },
      items: [
        { name: "Newest are first", value: "newest" },
        { name: "Oldest first", value: "oldest" },
        { name: "Lowest sum first", value: "lowest" },
        { name: "Biggest sum first", value: "biggest" }
      ]
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  methods: {
    async refresh() {
      this.isLoading = true
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        user_id: this.$route.params.id,
        sort: this.sortBy.value,
        date_from: this.date_from,
        date_to: this.date_to
      }
      this.dataList = await userTransactionListRequest(params)
          .then(response => {
            this.total = response.data.meta.total
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
          })
    },
    changedDate(val) {
      this.$nextTick(() => {
        this.date_to = val[1]
        this.date_from = val[0]
        this.refreshPagination()
      })
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  }
}
</script>

<style scoped>

</style>