<template>
  <v-container fluid grid-list-xl>
    <v-layout row>
      <v-flex xs12>
        <div class="content">
          <v-btn
            class="btn-back"
            tile
            flat
            @click="$router.push(`/moderation/user_list/user/${$route.params.id}`)"
          >
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            Back
          </v-btn>
          <user-transaction />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserTransaction from '../../../components/UserTransaction/UserTransaction'
export default {
  name: "UserTransactionHistory",
  components: {
    UserTransaction
  }
}
</script>

<style scoped>
.content {
  max-width: 1224px;
  margin: 0 auto;
  position: relative;
}

.btn-back {
  position: absolute;
  left: -120px;
  top: -8px;
}
</style>